<template>
    <v-flex pb-5 mb-4>
        <v-alert
          border="left"
          colored-border
          text
          prominent
          :type="tipo"
          elevation="2"
        >
          {{ texto }}
        </v-alert>

        <v-card
            class="mx-auto"
            max-width="100%"
            flat
        >
            <v-card-actions>
                <v-btn
                    text
                    small
                    color="primary"
                    :to="{ name: 'comprar' }"
                >
                    Volver a Comprar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    class="ml-2"
                    text
                    small
                    color="primary"
                    href="http://complejoamericano.com"
                >
                    ir al sitio Complejo Americano
                </v-btn>
            </v-card-actions>
        </v-card>
     </v-flex>
</template>

<script>

export default {
    data: () => ({
        texto: '',
        tipo: 'info'
    }),

    mounted () {
        switch (this.$route.query.r) {
            case 'success': {
                this.tipo = 'success'
                this.texto = 'El pago fue acreditado! ¡Muchas Gracias!'
                break;
            }
            case 'pending': {
                this.tipo = 'warning'
                this.texto = 'El pago está pendiente, su compra quedará pendiente hasta que Mercado Pago nos notifique que se acreditó el importe total.'
                break;
            }
            case 'failure': {
                this.tipo = 'error'
                this.texto = 'El pago fue cancelado. Por favor utilice otra alternativa de pago. Si tuvo algún inconveniente con el proceso de pago no dude en comunicarse con nosotros.'
                break;
            }
        }
    }
}

</script>